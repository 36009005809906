import { createElement } from 'react';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import { HERO_BANNER } from '@gemini/shared/utils/testing';

export function HeroCarousel(props: any) {
  const { data } = props;
  const { templates, formatter } = data;
  const isCarousel = templates.length > 1;
  const templateData = templates[0].data;
  const renderElem = () => {
    let elem = <></>;
    if (isCarousel) {
      /**
       * Mocked code. Remove after we reach the carousel
       */
      const bannerData = templateData.templates[0];
      bannerData.data = {
        ...bannerData.data,
        ...{ formatterProps: !formatter.component ? null : formatter.data }
      };

      elem = createElement(TemplateRenderer, {
        ...bannerData,

        ...{ key: `template-${0}-${bannerData.component}` }
      });
    } else {
      const bannerData = templateData.templates[0];

      bannerData.data = {
        ...bannerData.data,
        ...{ formatterProps: !formatter.component ? null : formatter.data }
      };

      elem = createElement(TemplateRenderer, {
        ...bannerData,

        ...{ key: `template-${0}-${bannerData.component}` }
      });
    }

    return elem;
  };

  if (templateData.templates) {
    return (
      <div
        data-testid={HERO_BANNER}
        className={
          formatter.data?.header?.data === 'header' ? 'lg:mt-header' : ''
        }
      >
        {renderElem()}
      </div>
    );
  }

  return <></>;
}

export default HeroCarousel;
